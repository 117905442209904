import React, { useRef, useEffect } from "react";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/dist/ScrollTrigger";
// import InputTextBox from "../../InputTextBox"
import '../index.scss'
import SectionOne from "./SectionOne";
import SectionTwo from "./SectionTwo";
import SectionFour from "./SectionFour";
import SectionThree from "./SectionThree";
import StaryNightBackground from "../../StarBackground";


function ScrollSection() {
  const sectionRef = useRef(null);
  const triggerRef = useRef(null);

  gsap.registerPlugin(ScrollTrigger);

  useEffect(() => {

    const pin = gsap.fromTo(
      sectionRef.current,
      {
        translateX: 0,
      },
      {
        translateX: "-200vw",
        ease: "none",
        scrollTrigger: {
          trigger: triggerRef.current,
          pin: true,
          scrub: 1,
          snap: {
            snapTo: 0.5,
            duration: 0.1,
            delay: 0.1,
            ease: "power1.inOut"
          },
          // base vertical scrolling on how wide the container is so it feels more natural.
          end: "+=3500"
        }

        // translateX: "-300vw",
        // ease: "none",
        // duration: 1,
        // scrollTrigger: {
        //   trigger: triggerRef.current,
        //   start: "top top",
        //   end: "2000 top",
        //   scrub: 0.6,
        //   snapTo: 0.25,
        //   pin: true,
        // },

      }
    );
    return () => {
      {/* A return function for killing the animation on component unmount */ }
      pin.kill();
    };
  }, []);



  return (
    <section className="scroll-section-outer">
      {/* The section up act just as a wrapper. If the trigger (below) is the
      first jsx element in the component, you get an error on route change */}

      {/* The div below act just as a trigger. As the doc suggests, the trigger and 
      the animation should alway be two separated refs */}
      <div ref={triggerRef}>
        <div ref={sectionRef} className="scroll-section-inner">
          <StaryNightBackground/>
          
          <SectionOne className="scroll-section"/>
          <SectionTwo className="scroll-section"/>
          <SectionThree className="scroll-section"/>

        </div>
      </div>
    </section>
  );
}

export default ScrollSection;
