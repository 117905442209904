import React from "react";
import '../index.scss'

function Hero() {
  return (
    <header className="hero__header">
      <h1>Suleyman Kiani</h1>
      <h2>Fullstack Developer and Chess Enthusiast</h2>
      <p>
        My passion for chess and software development converge seamlessly on this page. As a Fullstack Developer, I thrive on solving complex problems and creating efficient solutions – skills I also employ on the chessboard. In the spirit of this connection, I decided to take on an exciting project: leveraging the Chess.com API to gather a wealth of player data and couple it with information obtained via web scraping each player's webpage. The result is an innovative, user-friendly resource that showcases my technical abilities and emphasizes my resourcefulness in integrating multiple data sources. I'm excited to bring this same innovative spirit and technical prowess to my professional pursuits.
      </p>
    </header>
  );
}

export default Hero;

