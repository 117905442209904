import React from 'react';

const InfoList = ({ infoList, current, previous, next, infoContainerRef }) => {
  return (
    <div className="infoList">
      <div className="info__wrapper" ref={infoContainerRef}>
          {infoList.map((info, index) => (
            <div
              className={`info ${index === current ? 'current--info' : index === previous ? 'previous--info' : index === next ? 'next--info' : ''}`}
              key={info.name}
            >
              <h1 className="text name">{info.postDate}</h1>
              <h4 className="text location">{info.user}</h4>
              <p className="text description">{info.description}</p>
            </div>
          ))}
        </div>
    </div>
  );
};

export default InfoList;