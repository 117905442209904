import './index.scss'
import { gsap } from 'gsap';
import React, { useState, useEffect } from 'react';
import Background from './Background.js';
import InfoList from './InfoList.js';
import CardList from './CardList.js';
import { useRef } from 'react';
import Loader from './Loader.js';

const Carousel = () => {

  const [loaderProgress, setLoaderProgress] = useState(0);
  const cardContainerRef = useRef(null);
  const infoContainerRef = useRef(null);
  // const backgroundContainerRef = useRef(null);


  const prev = useRef(null)
  const next = useRef(null)


  const [previousCard, setPreviousCard] = useState(2);
  const [currentCard, setCurrentCard] = useState(0);
  const [nextCard, setNextCard] = useState(1);


  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);


  useEffect(() => {
    if (loading && cardContainerRef.current && infoContainerRef.current){
        // console.log('loading wrapper found');
        
        loadingAnimation();
    }
  }, [loading]);

  

  //set initial cardContainer
  useEffect(() => {
    if (!loading && cardContainerRef.current && infoContainerRef.current){
        // console.log('cardContainerRef found');
        // console.log('infoContainerRef found');
        initializeAnimation();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading]);

  useEffect(() => {

    fetch('https://dwbiekfq3qvye.cloudfront.net/fetchInstagram')
    .then(response => response.json())
    .then(data => {
      const post1 = data.posts.post1;  // Get the first post
      const post2 = data.posts.post2; // Get the second post
      const post3 = data.posts.post3; // Get the third post

      // Get the first image from each post
      const images = [post1.images[0], post2.images[0], post3.images[0]];

      // Map the images to your data format
      const newData = images.map((image, index) => ({
          user: "@svley",
          name: image.name,
          location: data.posts[`post${index+1}`].location,
          postDate: data.posts[`post${index+1}`].date,
          description: data.posts[`post${index+1}`].description,
          status: index === 0 ? 'current' : (index === 1 ? 'next' : 'previous'),
          imageSrc: image.imageSrc,
        // const post1 = data.posts.post1;  // Get the first post

        // // Map the images to your data format
        // const newData = post1.images.map((image, index) => ({
        //     name: image.name,
        //     location: post1.location,
        //     postDate: post1.date,
        //     description: post1.description,
        //     status: index === 0 ? 'current' : (index === 1 ? 'next' : 'previous'),
        //     imageSrc: image.imageSrc,
        }));

        // console.log(newData)
        setData(newData);

        // Preload the images
        const preloadImages = async () => {
          return Promise.all(
              newData.map(item => {
                  return new Promise((resolve, reject) => {
                      const img = new Image();
                      img.src = item.imageSrc;
                      img.onload = () => {
                          setLoaderProgress(prev => prev + 1);  // update loaderProgress
                          resolve();
                      }
                      img.onerror = reject;
                  });
              })
          );
        };

        // Once the images are loaded, set loading to false
        preloadImages()
            .then(() => {
                setLoading(false);
            })
            .catch((errors) => {
                // handle errors
            });
    })
    .catch(error => {
        console.error('Error fetching Instagram data:', error);
    });
}, []);

  const handleMouseMove = (e) => {
    const card = e.currentTarget;
    const box = card.getBoundingClientRect();
    const centerPosition = {
      x: box.left + box.width / 2,
      y: box.top + box.height / 2,
    };
    let angle = Math.atan2(e.pageX - centerPosition.x, 0) * (35 / Math.PI);
    gsap.set(card, {
      '--current-card-rotation-offset': `${angle}deg`,
    });
    const currentInfoEl = infoContainerRef.current.querySelector(".current--info");
    gsap.set(currentInfoEl, {
      rotateY: `${angle}deg`,
    });
  }

  const handleMouseLeave = (e) => {
    const card = e.currentTarget;
    const currentInfoEl = infoContainerRef.current.querySelector(".current--info");
    gsap.set(card, {
      '--current-card-rotation-offset': 0,
    });
    gsap.set(currentInfoEl, {
      rotateY: 0,
    });
  }

  const loadingAnimation = () => {
    gsap.set(cardContainerRef.current?.children, {
        '--card-translateY-offset': '100vh',
    });
    gsap.set(infoContainerRef.current?.querySelector('.current--info')?.querySelectorAll('.text'), {
        translateY: '40px',
        opacity: 0,
    });
    gsap.set([prev.current, next.current], {
        pointerEvents: 'none',
        opacity: '0',
    });
  };

  const initializeAnimation = () => {
    requestAnimationFrame(() => {
      if (cardContainerRef.current && cardContainerRef.current.children &&
        infoContainerRef.current && infoContainerRef.current.querySelector(".current--info") &&
        infoContainerRef.current.querySelector(".current--info").querySelectorAll(".text")) {
        
        let tl = gsap.timeline();
        
        tl.to(cardContainerRef.current.children, {
          delay: 0.15,
          duration: 0.5,
          stagger: {
            ease: "power4.inOut",
            from: "right",
            amount: 0.1,
          },
          "--card-translateY-offset": "0%",
        })
        .to(infoContainerRef.current.querySelector(".current--info").querySelectorAll(".text"), {
          delay: 0.5,
          duration: 0.4,
          stagger: 0.1,
          opacity: 1,
          translateY: 0,
        })
        .to(
          [prev.current, next.current],
          {
            duration: 0.4,
            opacity: 1,
            pointerEvents: "all",
          },
          "-=0.4"
        );
      } else {
        // Elements don't exist yet, retry after half a second
        setTimeout(initializeAnimation, 500);
      }
    });
  }
  

  const swapCards = (direction) => {
    if (!cardContainerRef.current || !infoContainerRef.current || !cardContainerRef.current.children || !infoContainerRef.current.children) {
      console.warn('Container refs or their children are not defined yet');
      return;
    }
    let lastPrev = previousCard;
    let lastCurr = currentCard;
    let lastNext = nextCard;

    let tl = gsap.timeline();
    let currentCardEl = cardContainerRef.current.children[currentCard];
    let previousCardEl = cardContainerRef.current.children[previousCard];
    let nextCardEl = cardContainerRef.current.children[nextCard];
    let currentInfoEl = infoContainerRef.current.children[currentCard];
    let previousInfoEl = infoContainerRef.current.children[previousCard];
    let nextInfoEl = infoContainerRef.current.children[nextCard];

    // Initially set the z-indexes based on the current state
    gsap.set(currentCardEl, { zIndex: 3 });
    gsap.set(previousCardEl, { zIndex: 2 });
    gsap.set(nextCardEl, { zIndex: 2 });
    
    tl.to([prev.current, next.current], {
        duration: 0.2,
        opacity: 0.5,
        pointerEvents: "none",
    })
    .to(
      currentInfoEl.querySelectorAll(".text"),
      {
          duration: 0.4,
          stagger: 0.1,
          translateY: "-120px",
          opacity: 0,
      }
    )
    .add(() => {
      if (direction === 'right') {
        setCurrentCard(lastNext);
        setNextCard(lastPrev);
        setPreviousCard(lastCurr);

        // Adding z-index manipulation here
        // The outgoing image goes to the bottom of the stack
        gsap.set(previousCardEl, { zIndex: 1 });

      } else {
        setCurrentCard(lastPrev);
        setNextCard(lastCurr);
        setPreviousCard(lastNext);

        // Adding z-index manipulation here
        // The outgoing image goes to the bottom of the stack
        gsap.set(nextCardEl, { zIndex: 1 });
      }
    }, "-=0.2")
    .fromTo(
      direction === "right"
      ? nextInfoEl.querySelectorAll(".text")
      : previousInfoEl.querySelectorAll(".text"),
      {
          opacity: 0,
          translateY: "40px",
      },
      {
          duration: 0.4,
          stagger: 0.1,
          translateY: "0px",
          opacity: 1,
      }
    )
    .to([prev.current, next.current], {
      duration: 0.2,
      opacity: 1,
      pointerEvents: "all",
    });
  };


  if (loading) {
    return <Loader runAnimation={loaderProgress} totalImages={data ? data.length : 0} />
  }

  return (
    <div className='carousel_wrapper'>
      <div className="app">
          <CardList
            cards={data}
            current={currentCard}
            previous={previousCard}
            next={nextCard}
            onSwap={swapCards}
            onMouseMove={handleMouseMove}  // add this prop
            onMouseLeave={handleMouseLeave}  // add this prop
            prevRef={prev}
            nextRef={next}
            cardContainerRef={cardContainerRef}
          />
          <InfoList
            infoList={data}
            current={currentCard}
            previous={previousCard}
            next={nextCard}
            infoContainerRef={infoContainerRef}
          />
        <Background
          images={data}
          current={currentCard}
          previous={previousCard}
          next={nextCard}
        />
      </div>
    </div>
  )
}

export default Carousel