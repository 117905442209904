import './index.scss'

import './index.scss';

const AnimatedLetters = ({ letterClass, strArray, idx, conditionalClasses }) => {
  return (
    <span>
      {strArray.map((char, i) => (
        <span
          key={char + i}
          className={`${letterClass} _${i + idx} ${
            conditionalClasses ? conditionalClasses[i] : ''
          }`}
        >
          {char}
        </span>
      ))}
    </span>
  );
};

export default AnimatedLetters;