import './index.scss'
import Loader from 'react-loaders';
import Hero from '../ScrollHorizontal/Hero/Hero';
import Footer from '../ScrollHorizontal/Footer/Footer';
import ScrollSection from '../ScrollHorizontal/ScrollSection/ScrollSection';
const Chess = () => {
  
    return (
        <>
            <div className='chess-page'>
              <div className='horizontal_scroll'>
                <Hero />
                <ScrollSection />
                <Footer />
              </div>
            </div>
          <Loader type="pacman" />
        </>
      );
      
}

export default Chess