import React from "react";
import { Link } from 'react-router-dom';
import '../index.scss'


function Footer() {
  return (
    <footer className="footer">
      <p>
        Throughout this journey, you've explored my user profile, compared chess players, delved into a calendar of daily puzzles, and even interacted with a chess engine of my creation. This page encapsulates my passion for both chess and software development - a testament to the fascinating blend of strategy, creativity, and logic that binds the two.
      </p>
      <p>
        If you have any questions or wish to discuss potential collaborations, feel free to <Link to="/contact">contact me</Link>.
      </p>
      <p>© {new Date().getFullYear()} Suleyman Kiani</p>
    </footer>
  );
}

export default Footer;
