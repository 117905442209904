import './App.scss';
import { Routes, Route } from 'react-router-dom';
import Layout from './components/Layout';
import Home from './components/Home';
import About from './components/About';
import Contact from './components/Contact';
import Chess from './components/Chess';
import Projects from './components/Projects'
import Socials from './components/Socials'


function App() {
  return (
    <>
      <Routes> 
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />}/>
          <Route path="/about" element={<About />}/>
          <Route path="/contact" element={<Contact />}/>
          <Route path="/chess" element={<Chess />}/>
          <Route path="/projects" element={<Projects />}/>
          <Route path="/socials" element={<Socials />}/>
        </Route>
      </Routes>
    </>
  )
}
export default App
