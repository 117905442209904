import './index.scss'
import Carousel from '../Carousel';

const Socials = () => {

    return (

        <div className='socials-page'>
            <Carousel/>
        </div>
    )
}

export default Socials