// ArrowRight.js
import React from 'react';

const ArrowRight = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
    <polyline
      points="184 112 328 256 184 400"
      style={{ fill: 'none', stroke: '#fff', strokeLinecap: 'round', strokeLinejoin: 'round', strokeWidth: '48px' }}
    />
  </svg>
);

export default ArrowRight;