import "./index.scss";
import React, { useState, useEffect } from 'react';

const PracticeCard = ({ username }) => {
    const [userDetails, setUserInfo] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchData = async () => {
        setLoading(true);

        const profile = await getUserInfo(username);
        setUserInfo(profile);
        console.log(profile)

        setLoading(false);
        };
        fetchData();
    }, [username]);

    async function getUserInfo(username) {
        console.log('Fetching data for', username, 'with current userDetails:', userDetails);
        const response = await fetch(`https://dwbiekfq3qvye.cloudfront.net/userData/${username}`);
        const data = await response.json();
        return data;
    }
    if (loading || !userDetails || !userDetails.userInfo) {
        return <div>Loading...</div>;
    }

    return (
        <>
        <div className="player-card">
            <div className="ds-top"></div>
            <div className="avatar-holder">
                <img src={userDetails?.userInfo?.avatar} alt={`${userDetails?.userInfo?.username}'s avatar`} />
            </div>

            <div className="name">
                <a href={userDetails.userInfo.url} target="_blank">{userDetails.userInfo.name}</a>
                <h6 title="Last"><i className="fas fa-users"></i> <span className="title">Stats</span></h6>
            </div>


            <div className="ds-info">
                <div className="ds bullet">
                    <h6 title="Bullet Score">Bullet <i className="fas fa-edit"></i></h6>
                    <p>{userDetails.userInfo.best_bullet}</p>
                </div>
                <div className="ds blitz">
                    <h6 title="Blitz Score">Blitz <i className="fas fa-project-diagram"></i></h6>
                    <p>{userDetails.userInfo.best_blitz}</p>
                </div>
                <div className="ds rapid">
                    <h6 title="Rapid">Rapid <i className="fas fa-comments"></i></h6>
                    <p>{userDetails.userInfo.best_rapid}</p>
                </div>
                {/* <div class="ds puzzle">
                    <h6 title="Puzzle">Tactics <i class="fas fa-comments"></i></h6>
                    <p>{userStats.chess_rapid.best.rating}</p>
                </div> */}
            </div>
            
            <div className="ds-skill">
                <h6>Accuracy by game phase <i className="fa fa-code" aria-hidden="true"></i></h6>
                
                <div className="skill html">
                    <h6>
                        <i className="fab fa-html5"></i> 
                        Opening
                    </h6>
                    <div className="bar bar-html" style={{ width: `${userDetails.insightData.openingAccuracy}%` }} >
                        <p>{userDetails.insightData.openingAccuracy}%</p>
                    </div>
                </div>

                <div className="skill css">
                    <h6><i className="fab fa-css3-alt"></i> Middlegame </h6>
                    <div className="bar bar-css" style={{ width: `${userDetails.insightData.middlegameAccuracy}%` }}>
                        <p>{userDetails.insightData.middlegameAccuracy}%</p>
                    </div>
                </div>

                <div className="skill javascript">
                    <h6><i className="fab fa-js"></i> Endgame </h6>
                    <div className="bar bar-js" style={{ width: `${userDetails.insightData.endgameAccuracy}%` }}>
                        <p>{userDetails.insightData.endgameAccuracy}%</p>
                    </div>
                </div>
            </div>
        </div>
        </>
    );
};

export default PracticeCard;