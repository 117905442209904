import React, { useState } from 'react';
import axios from 'axios';
import './Login.scss';

const LoginPage = ({ toggleForm, onLogin, setLoggedIn }) => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  const handleSubmit = async (e) => {
      e.preventDefault();
      try {
          const res = await axios.post('https://dwbiekfq3qvye.cloudfront.net/login', { username, password });
          if (res.status === 200) {
            onLogin(res.data.userInfo); // Pass the user info to the onLogin function
            setLoggedIn(true); // Set the login status to true
            localStorage.setItem('token', res.data.token);  // Store the JWT token in localStorage
            console.log(localStorage.getItem('token'));  // Log the stored token

            }
      } catch (err) {
          console.error('Error logging in:', err);
          alert('Failed to log in');
      }
  }

  const handleFormSwitch = (e) => {
      e.preventDefault();
      toggleForm();
  };

  return (
      <div className="login-page">
          <div className="avatar">
              <img src="https://cdn.pixabay.com/photo/2014/04/02/14/10/female-306407_960_720.png" alt="Avatar" />
          </div>
          <div className="form">
              <h2>Login</h2>
              <form onSubmit={handleSubmit}>
                  <input type="text" placeholder="Username" value={username} onChange={(e) => setUsername(e.target.value)} required />
                  <input type="password" placeholder="Password" value={password} onChange={(e) => setPassword(e.target.value)} required />
                  <button type="submit">Log in</button>
              </form>
              <p className="message">Not registered? <a href="#" onClick={handleFormSwitch}>Create an account</a></p>
          </div>
      </div>
  );
}

export default LoginPage;


