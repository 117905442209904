import React, { useRef } from 'react';
import ArrowLeft from './ArrowLeft';
import ArrowRight from './ArrowRight';

const CardList = ({ cards, current, previous, next, onSwap, onMouseMove, onMouseLeave, prevRef, nextRef, cardContainerRef }) => {

  return (
    <div className="cardList">
      <button className="cardList__btn btn btn--left" onClick={() => onSwap('left')} ref={prevRef}>
          <div className="icon">
            <ArrowLeft />
          </div>
        </button>
        <div className="cards__wrapper" ref={cardContainerRef}>
          {cards.map((card, index) => (
            <div
              className={`card ${index === current ? 'current--card' : index === previous ? 'previous--card' : index === next ? 'next--card' : ''}`}
              key={card.name}
              onMouseMove={onMouseMove}
              onMouseLeave={onMouseLeave}
            >
              <div className="card__image">
                <img src={card.imageSrc} alt="" />
              </div>
            </div>
          ))}
        </div>
        <button className="cardList__btn btn btn--right" onClick={() => onSwap('right')} ref={nextRef}>
          <div className="icon">
            <ArrowRight />
          </div>
        </button>
    </div>
  );
};

export default CardList;