import './index.scss'
import { Link, NavLink } from 'react-router-dom' 
import LogoS from '../../assets/images/logo-s.png'
// import LogoSubtitle from '../../assets/images/logo_sub.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faEnvelope, faHome, faUser, faFileAlt, faChess, faList, faCode} from "@fortawesome/free-solid-svg-icons"
import {
    faGithub,
    faInstagram,
    faLinkedin
  } from '@fortawesome/free-brands-svg-icons'

// import leetCodeLogo from '../../assets/images/leetcode.svg'

const Sidebar = () => (
    <div className ='nav-bar'>
      
        {/*Image of logo will reroute to homepage */}
        <Link className='logo' to='/'>
            <img src={LogoS} alt='logo'/>
            {/* <img className='sub-logo' src={LogoSubtitle} alt='slobodan'/> */}
        </Link>
        <nav>
            <NavLink exact="true" activeclassname="active" to="/">
                <FontAwesomeIcon icon={faHome} color='#4d4d4e' />
            </NavLink>
            <NavLink exact="true" activeclassname="active" className='about-link' to="/about">
                <FontAwesomeIcon icon={faUser} color='#4d4d4e' />
            </NavLink>         
            <NavLink exact="true" activeclassname="active" className='chess-link' to="/chess">
                <FontAwesomeIcon icon={faChess} color='#4d4d4e' />
            </NavLink>
            <NavLink exact="true" activeclassname="active" className='projects-link' to="/projects">
                <FontAwesomeIcon icon={faList} color='#4d4d4e' />
            </NavLink>
            <NavLink exact="true" activeclassname="active" className='socials-link' to="/socials">
                <FontAwesomeIcon icon={faInstagram} color='#4d4d4e' />
            </NavLink>
            <NavLink exact="true" activeclassname="active" className='contact-link' to="/contact">
                <FontAwesomeIcon icon={faEnvelope} color='#4d4d4e' />
            </NavLink>
        </nav>
        <ul>
            <li>
                <a
                    target='_blank'
                    rel='noreferrer'
                    href='https://www.linkedin.com/in/suleyman-kiani-9249a0240/'
                >
                    <FontAwesomeIcon icon={faLinkedin} color='#4d4d4e'/>
                </a>
            </li>
            <li>
                <a
                    target='_blank'
                    rel='noreferrer'
                    href='https://github.com/kianis4'
                >
                    <FontAwesomeIcon icon={faGithub} color='#4d4d4e'/>
                </a>
            </li>
            <li>
                <a
                    target='_blank'
                    rel='noreferrer'
                    href='https://github.com/kianis4/Resume/blob/main/Suleyman_Resume_2023.pdf'
                >
                    <FontAwesomeIcon icon={faFileAlt} color='#4d4d4e'/>
                </a>
            </li>
            <li>
                <a
                    target='_blank'
                    rel='noreferrer'
                    href='https://leetcode.com/kianis4/'
                >
                    <FontAwesomeIcon icon={faCode} color='#4d4d4e' />
                </a>
            </li> 
        </ul>
    </div>
)

export default Sidebar;