import './index.scss';
import LogoTitle from '../../assets/images/logo-s.png'
import { Link } from 'react-router-dom';
import { useState, useEffect } from 'react';
import AnimatedLetters from '../AnimatedLetters'
import Logo from './Logo';
import Loader from 'react-loaders';

const Home = () => {
    const[letterClass, setLetterClass] = useState('text-animate')
    const nameArray = ['u','l','e','y','m','a','n',',']
    const jobArray = ['a',' ','S','o','f','t','w','a','r','e',' ', 'd','e','v','e','l','o','p','e','r','.']
    const conditionalClassesArray = ['', '', '', '', '', '', '', '', '', '', 'line-break-mobile', '', '', '', '', '', '', '', '', '', ''];

    useEffect(() => {
      const timer = setTimeout(() => {
          setLetterClass('text-animate-hover');
      }, 4500);
  
      return () => {
          clearTimeout(timer);
      };
  }, []);
    
    return (
      <>
        <div className='container home-page'>
            <div className='text-zone'>
                <h1>
                    <span className={letterClass}>H</span>
                    <span className={`${letterClass} _12`}>i,</span>
                  <br />
                    <span className={`${letterClass} _13`}>I</span>
                    <span className={`${letterClass} _14`}>'m</span>
                    <img src={LogoTitle} alt='developer' />
                    <AnimatedLetters letterClass={letterClass}
                    strArray={nameArray}
                    idx={15}/>
                    <br/>
                    <span className="line-break-mobile"></span> {/* Add this span */}
                    <AnimatedLetters letterClass={letterClass}
                    strArray={jobArray}
                    idx={22}
                    conditionalClasses={conditionalClassesArray} // Pass the new prop here
                    />
                </h1>

                <div className="info-wrapper">
                  <h2> Full-stack Developer / Solutions Provider / Programmer</h2>
                  <Link to="/contact" className='flat-button'>
                    CONTACT ME
                  </Link>
                </div>
            </div>
          <Logo />
        </div>
        <Loader type="pacman" />
      </>
    );
}

export default Home