// ArrowLeft.js
import React from 'react';

const ArrowLeft = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
    <polyline
      points="328 112 184 256 328 400"
      style={{ fill: 'none', stroke: '#fff', strokeLinecap: 'round', strokeLinejoin: 'round', strokeWidth: '48px' }}
    />
  </svg>
);

export default ArrowLeft;