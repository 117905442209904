import { useEffect } from 'react';
import Sidebar from '../Sidebar';
import './index.scss';
import { Outlet } from 'react-router-dom';
import { useLocation } from 'react-router-dom';


const Layout = () => {
  const location = useLocation();
  const hideOverflowPages = ['/', '/about', '/projects', '/socials', '/contact'];
  const shouldHideOverflow = hideOverflowPages.includes(location.pathname);


  return (

    <div className="App">
      <Sidebar />
      <div className={`page ${shouldHideOverflow ? 'overflow-hidden' : ''}`}>
        <span className="tags top-tags">&lt;body&gt;</span>

        <Outlet />
        <span className="tags bottom-tags">
          &lt;/body&gt;
          <br />
          <span className="bottom-tag-html">&lt;/html&gt;</span>
        </span>
      </div>
    </div>
  )
}

export default Layout
