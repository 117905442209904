// Background.js
const Background = ({ images, current, previous, next }) => {
    return (
      <div className="app__bg">
        {images.map((image, index) => (
          <div 
            className={`app__bg__image ${index === current ? 'current--image' : index === previous ? 'previous--image' : index === next ? 'next--image' : ''}`}
            key={image.name}
          >
            <img src={image.imageSrc} alt="" />
          </div>
        ))}
      </div>
    );
  };
  
  export default Background;
  