import '../../index.scss'
import React, { useEffect, useState } from "react";
// import InputTextBox from "../../InputTextBox"
import UserInputForm from '../../../InputTextBox/index';
import axios from 'axios';
import PracticeCard from '../../../Chess/PracticeCard';

const SectionOne = () => {
    const [validatedUsername, setValidatedUsername] = useState(null);
    const [isCardAdded, setIsCardAdded] = useState(false); // New state to track the new card
    const [isInvalid, setIsInvalid] = useState(false);
    const [errorMessage, setErrorMessage] = useState("Enter username ...");
    
    const handleFormSubmit = async (username) => {
        try {
            const res = await axios.get(`https://dwbiekfq3qvye.cloudfront.net/userData/${username}`);
            console.log(res.data)
            
            if(res.status === 200) { // Check if response is 200
                setValidatedUsername(username);
                setIsCardAdded(true); // Set to true after username is validated
                setIsInvalid(false); // reset the invalid state
                setErrorMessage("Enter username ..."); // reset the error message
            }
            
        }catch (err) {
            console.log(err.status);
            if (err.response && err.response.status === 404) {
                setIsInvalid(true);
                setErrorMessage('Invalid user');
            } else {
                setIsInvalid(true);
                setErrorMessage('An error occurred');
            }
        }
    }
    
    useEffect(() => {
        if(isCardAdded) {
          //Do animation
        }
    }, [isCardAdded]); // Monitor the isCardAdded state

    return(
        <div className="scroll-sectionOne">
            <h3>
                Enter your chess.com username:
            </h3>
            <div className="cardsContainer">
                <PracticeCard username={'suleymankiani'}/>
                <UserInputForm onFormSubmit={handleFormSubmit} isInvalid={isInvalid} errorMessage={errorMessage} />
                {validatedUsername && <PracticeCard username={validatedUsername}/>}
            </div>
        </div>
    )
}
export default SectionOne;

