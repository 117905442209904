
import StaryNightBackground from '../../../StarBackground/index'
import '../../index.scss'

const SectionThree = () => {
    return(
        <>
            <div className="scroll-sectionThree">
                <StaryNightBackground/>
                <h3>Engine Section...</h3>
                <h1>On The Way!</h1>

            </div>
        </>
    )
}

export default SectionThree