// SectionTwo.js
import '../../index.scss'
import { useState } from 'react';
import Login from '../../../LoginRegister/Login'
import SignUp from '../../../LoginRegister/Register'
import UserCard from './UserCard/UserCard.js';

const SectionTwo = () => {
    const [isLogin, setIsLogin] = useState(true);
    const [isLoggedIn, setLoggedIn] = useState(false); // new state to keep track of the login status
    const [userInfo, setUserInfo] = useState(null); // new state to store user information
    
    const onAvatarUpdate = (newAvatar) => {
        setUserInfo(prevState => ({ ...prevState, avatar: newAvatar }));
    };

    const toggleForm = () => {
        setIsLogin(!isLogin);
    };

    return(
        <>
            <div className="scroll-sectionTwo">
                <h3>Current Daily Puzzle</h3>
                <div className='content-wrapper'>
                    {!isLoggedIn ? (isLogin ? <Login toggleForm={toggleForm} onLogin={setUserInfo} setLoggedIn={setLoggedIn} /> : <SignUp toggleForm={toggleForm} />) : null}
                    {isLoggedIn && (
                        <>
                            <div className='puzzle-wrapper'>
                                <iframe
                                style={{ width: '319px', height: '349px', overflow:'hidden' }}
                                src="https://www.chess.com/daily_puzzle/"
                                sandbox="allow-same-origin allow-scripts"
                                frameBorder="0"
                                ></iframe>
                            </div>
                            {userInfo && (
                                
                                <div>
                                    <UserCard user_data={userInfo} onAvatarUpdate={onAvatarUpdate} onLogout={() => setLoggedIn(false)} />
                                </div>
                            )}
                        </>
                    )}
                </div>
            </div>
        </>
    )
}

export default SectionTwo;



