import React, { useState, useEffect } from 'react';
import './index.scss'

const UserInputForm = ({ onFormSubmit, isInvalid, errorMessage }) => {
  const [username, setUsername] = useState("");
  const [isFocused, setIsFocused] = useState(false);

  const handleChange = (event) => {
    setUsername(event.target.value);
  }

  const handleFocus = () => {
    setIsFocused(true);
  }

  const handleBlur = () => {
    if (!username) {
      setIsFocused(false);
    }
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    onFormSubmit(username);
    setUsername(''); // Clear the input field after submission
  }

  // Add this function
  const handleSpanClick = () => {
    if (username) { // Only submit if username is not empty
      onFormSubmit(username);
      setUsername(''); // Clear the input field after submission
    }
  }

  useEffect(() => {
    if (!isInvalid) {
      setIsFocused(false); // Reset the span
    }
  }, [isInvalid]);

  return (
    <form onSubmit={handleSubmit} className="wrapper">
      <div>
      <input 
        type="text" 
        placeholder={errorMessage}
        value={username} 
        onChange={handleChange}
        onFocus={handleFocus}
        onBlur={handleBlur} 
        className={isInvalid && isFocused ? 'error' : ''} 
        style={isFocused ? { transform: 'translateX(-6rem)' } : {}}
        required 
      />
      <span 
        className={isInvalid ? (isFocused ? 'focused error' : 'error') : (isFocused ? 'focused' : '')} 
        onClick={handleSpanClick}
      >
        Submit 
      </span>
      </div>
    </form>
  )
}

export default UserInputForm;

