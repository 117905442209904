import './index.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUpload, faSignOutAlt } from '@fortawesome/free-solid-svg-icons'


import React, { useState, useEffect, useRef } from 'react';
const UserCard = ({ user_data, onAvatarUpdate, onLogout}) => {
    const [userDetails, setUserInfo] = useState(null);
    const [loading, setLoading] = useState(true);
    const fileInput = useRef();


    useEffect(() => {
        setUserInfo(user_data);
        console.log(userDetails)
        setLoading(false);
    },[user_data]);

    const handleFileChange = async (event) => {
        const token = localStorage.getItem('token')
        const url = "https://dwbiekfq3qvye.cloudfront.net/uploadAvatar";
        const file = event.target.files[0];
        const formData = new FormData();
        formData.append('avatar', file);
    
        try {
            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
                body: formData,
            });
            
            if (!response.ok) throw new Error('Network response was not ok');
        
            const data = await response.json();
            
            onAvatarUpdate(data.userInfo.avatar);
        } catch (error) {
            console.error('Error uploading file:', error);
        }        
    };


    const handleLogout = () => {
        // Clear the local storage
        localStorage.removeItem('token');
        // Call the setLoggedIn function passed from the parent component
        onLogout();
      };

    if (loading || !userDetails) {
        return <div>Loading...</div>;
    }
    const registrationDate = new Date(userDetails.registrationDate).toLocaleDateString('en-US');


    return (
        <>
        <div className="user-card">
            <div className="ds-top"></div>
            <div className="avatar-background">
                <div className="avatar-holder">
                    <img src={`https://dwbiekfq3qvye.cloudfront.net/image/${userDetails?.avatar}`} alt={`${userDetails?.username}'s avatar`} />
                        <input type="file" onChange={handleFileChange} ref={fileInput} style={{ display: 'none' }} />
                            <button 
                            className="upload-button"
                            onClick={() => fileInput.current.click()}>
                            <FontAwesomeIcon icon={faUpload} />
                        </button>
                </div>
            </div>

            <div className="name">
                <a>{userDetails.username}</a>
                <h6 title="Last"><i className="fas fa-users"></i> <span className="title">{userDetails?.name}</span></h6>
            </div>


            <div className="ds-info">
                <div className="ds bullet">
                    <h6 title="Registration Date">Registered: <i className="fas fa-edit"></i></h6>
                    <p>{registrationDate}</p>
                </div>
                <div className="ds blitz">
                    <h6 title="Puzzle Solved">Puzzles Solved <i className="fas fa-project-diagram"></i></h6>
                    <p>{userDetails.puzzlesSolved}</p>
                </div>
                <div className="ds rapid">
                    <h6 title="Rapid">Log-In's <i className="fas fa-comments"></i></h6>
                    <p>{userDetails.loginCount}</p>
                </div>
                {/* <div class="ds puzzle">
                    <h6 title="Puzzle">Tactics <i class="fas fa-comments"></i></h6>
                    <p>{userStats.chess_rapid.best.rating}</p>
                </div> */}
            </div>
            <div className="button">
                <button onClick={handleLogout}>
                    <FontAwesomeIcon icon={faSignOutAlt} />
                </button>
            </div>
            
        </div>
        </>
    );
};

export default UserCard;