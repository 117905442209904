import './index.scss'
import AnimatedLetters from '../AnimatedLetters'
import { useState, useEffect } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPython, faReact, faJsSquare,faNodeJs,faAngular,faDocker } from '@fortawesome/free-brands-svg-icons';
import Loader from 'react-loaders'


const About = () => {
    const[letterClass, setLetterClass] = useState('text-animate')
    useEffect(() => {
        const timer = setTimeout(() => {
            setLetterClass('text-animate-hover');
        }, 3000);
    
        return () => {
            clearTimeout(timer);
        };
    }, []);

    return(
        <>
            <div className='container about-page'>
                <div className='text-zone'>
                    <h1> <AnimatedLetters
                            letterClass={letterClass}
                            strArray={['A', 'b', 'o', 'u', 't', ' ', 'm', 'e']}
                            idx={15}
                        />
                    </h1>
                    <p>
                        As a highly motivated full-stack developer, I am seeking a position within
                        a reputable IT organization where I can contribute to cutting-edge projects
                        and utilize the latest technologies. This opportunity will allow me to
                        engage with diverse challenges and contribute to the company's growth and
                        success.
                    </p>

                    <p>
                        As a confident yet humble and inquisitive individual, I am always
                        striving to improve my skills as both a front-end and back-end developer
                        by addressing one design problem at a time. My genuine curiosity and
                        love for problem-solving motivate me to embrace new challenges and
                        contribute meaningfully to any development project.
                    </p>
                    <p>
                        Passionate about continuous growth, I actively pursue challenges across a wide
                        range of interests, including software development, chess, basketball, and other
                        hobbies. My competitive spirit and adaptability enable me to excel individually
                        and contribute effectively to team dynamics, making me a valuable asset in
                        collaborative environments.
                    </p>

                    <p>
                        In a single sentence, I am a devoted family person, a passionate chess
                        player, a basketball fanatic, and a photography enthusiast, 
                        with a strong fascination for technology and a keen interest in fashion.
                    </p>

                
                </div>
                <div className='stage-cube-cont'>
                    <div className='cubespinner'>
                        <div className="face1">
                        <FontAwesomeIcon icon={faPython} color='#4B8BBE' />
                        </div>
                        <div className="face2">
                        <FontAwesomeIcon icon={faNodeJs} color='#303030' />
                        </div>
                        <div className="face3">
                        <FontAwesomeIcon icon={faReact} color="#5ED4F4" />
                        </div>
                        <div className="face4">
                        <FontAwesomeIcon icon={faJsSquare} color="#EFD81D" />
                        </div>
                        <div className="face5">
                        <FontAwesomeIcon icon={faAngular} color="#DD0031" />
                        </div>
                        <div className="face6">
                        <FontAwesomeIcon icon={faDocker} color='#0db7ed'/>
                        </div>
                    </div>
                </div>
            </div>
            <Loader type="pacman" />
        </>
    )

}

 
export default About