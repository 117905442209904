import './index.scss'
import AnimatedLetters from '../AnimatedLetters';
import Loader from 'react-loaders';
import { useState,useEffect } from 'react';

const Projects = () => {

    const[letterClass, setLetterClass] = useState('text-animate')
    useEffect(() => {
        const timer = setTimeout(() => {
            setLetterClass('text-animate-hover');
        }, 3000);
    
        return () => {
            clearTimeout(timer);
        };
    }, []);

    return (
        <>
            <div className='container projects-page'>
                <div className='text-zone'>
                    <h1> <AnimatedLetters
                            letterClass={letterClass}
                            strArray={['C', 'o', 'm', 'i', 'n', 'g', ' ', 's','o','o','n']}
                            idx={15}
                        />
                    </h1>
                </div>
            </div>
            <Loader type="pacman" />
        </>
    )
}

export default Projects