import React, { useState } from 'react';
import axios from 'axios';
import './Login.scss';

const SignUp = ({ toggleForm }) => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const res = await axios.post('https://dwbiekfq3qvye.cloudfront.net/signup', { username, password });
            alert('User created successfully');
        } catch (err) {
            console.error('Error creating user:', err);
            // Check if error response exists and contains data
            if (err.response && err.response.data && err.response.data.message) {
                // Show the custom error message from the server
                alert(err.response.data.message);
            } else {
                // If there's no custom message, show a generic error message
                alert('Failed to create user');
            }
        }
    }

    const handleFormSwitch = (e) => {
        e.preventDefault();
        toggleForm();
    };

    return (
        <div className="register-page">
            <div className="avatar">
                <img src="https://cdn.pixabay.com/photo/2014/04/02/14/10/female-306407_960_720.png" alt="Avatar" />
            </div>
            <div className="form">
                <h2>Sign up</h2>
                <form onSubmit={handleSubmit}>
                    <input type="text" placeholder="Username" value={username} onChange={(e) => setUsername(e.target.value)} required />
                    <input type="password" placeholder="Password" value={password} onChange={(e) => setPassword(e.target.value)} required />
                    <button type="submit">Register</button>
                </form>
                <p className="message">Already registered? <a href="#" onClick={handleFormSwitch}>Log-in</a></p>
            </div>
        </div>
    );
}

export default SignUp;


