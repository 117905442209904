import React, { useEffect, useRef } from 'react';
import { gsap } from 'gsap';

const Loader = ({ runAnimation, totalImages }) => {
  const loaderRef = useRef(null);
  
  useEffect(() => {
    const loadProgress = totalImages > 0 ? runAnimation / totalImages : 0;
    // console.log(loadProgress)
    // console.log(loaderRef.current)
    //style="translate: none; rotate: none; scale: none; background-color: rgb(255, 0, 0); transform: scale(0, 1);"
    gsap.to(loaderRef.current, {  
      duration: 1,
      scaleX: loadProgress,
      backgroundColor: `hsl(${loadProgress * 120}, 100%, 50%)`,
    });
  }, [runAnimation, totalImages]);

  return (
    <div className="loading__wrapper">
      <div className="loader--text">Loading...</div>
      <div className="loader">
        <span ref={loaderRef}></span>
      </div>
    </div>
  );
};

export default Loader;